<template>
  <NuxtRouteAnnouncer />
  <NuxtLayout>
    <NuxtPwaAssets />
    <VitePwaManifest />
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup>
const { load } = useNavigation()
await load()

</script>
<style module>
@import url("~/css/main.css");
@import url("~/css/_helpers");
</style>
