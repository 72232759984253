import { default as _91_46_46_46slug_93i42GoiidIXMeta } from "/var/lib/jenkins/workspace/Master/pages/[...slug].vue?macro=true";
import { default as pers_45kittrD6eMfz1IMeta } from "/var/lib/jenkins/workspace/Master/pages/pers-kit.vue?macro=true";
import { default as quotemakerykjTXON8PwMeta } from "/var/lib/jenkins/workspace/Master/pages/quotemaker.vue?macro=true";
import { default as social_45mediaCP6WRVfzG7Meta } from "/var/lib/jenkins/workspace/Master/pages/social-media.vue?macro=true";
import { default as styleguideMYX88DdOE9Meta } from "/var/lib/jenkins/workspace/Master/pages/styleguide.vue?macro=true";
import { default as component_45stubN5r1RucVicMeta } from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubN5r1RucVic } from "/var/lib/jenkins/workspace/Master/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/[...slug].vue")
  },
  {
    name: "pers-kit",
    path: "/pers-kit",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/pers-kit.vue")
  },
  {
    name: "quotemaker",
    path: "/quotemaker",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/quotemaker.vue")
  },
  {
    name: "social-media",
    path: "/social-media",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/social-media.vue")
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/var/lib/jenkins/workspace/Master/pages/styleguide.vue")
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/verhuurfolder",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/filmpjes",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/sponsoren",
    component: component_45stubN5r1RucVic
  },
  {
    name: component_45stubN5r1RucVicMeta?.name,
    path: "/videos-2023",
    component: component_45stubN5r1RucVic
  }
]