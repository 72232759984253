<template>
  <Container :class="$style['error']" no-padding-top>
    <Logo />
    <h1>Oeps</h1>
    <div>Er ging iets fout</div>
    {{ error.stack }}
    <Button transparent with-border class="h-margin-top-xl" icon="ArrowRight" icon-pos="right">
      Breng me naar de homepage
    </Button>
  </Container>
</template>
<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

console.error(props.error.stack)
</script>
<style  module>
.error {
  display: flex;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
