<template>
  <header :class="$style['top-bar']">
    <div :class="$style['container']">
      <Button
        :to="{path: '/'}"
        no-border-radius
        color="transparent"
        aria-label="Hengelose Revue"
        :class="$style['top-bar__button']"
        styleless>
        <Logo :class="$style['top-bar__logo']" />
      </Button>
      <div :class="$style['top-bar__content']">
        <slot />
      </div>
      <div v-if="!!$slots.aside" :class="$style['top-bar__aside']">
        <slot name="aside" />
      </div>
      <div :class="$style['top-bar__mobile-content']">
        <slot name="mobile" />
      </div>
    </div>
  </header>
</template>
<style module>
.top-bar {
  background: linear-gradient(0deg, rgb(0 0 0 / 0%), rgb(0 0 0 / 90%));
  color: var(--secondary-on-surface);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  container: topbar / inline-size;
  transition: background var(--duration-micro-normal) var(--easing-transition);
}

.top-bar__button {
  margin-left: calc(var(--spacing) * -1);
}

.top-bar__logo {
  width: 15rem;
}

.top-bar__content {
  display: flex;
}

.top-bar__content * {
  min-height: 100%;
}

@container (max-width: 70rem) {
  .top-bar__content {
    display: none;
  }
}

.top-bar__aside {
  display: flex;
  margin-left: auto;
}

@container (max-width: 70rem) {
  .top-bar__aside {
    display: none;
  }
}

.top-bar__mobile-content {
  display: flex;
  margin-left: auto;
}

@container (min-width: 70rem) {
  .top-bar__mobile-content {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: env(titlebar-area-height, 0);
  height: calc(var(--topbar-height) + env(titlebar-area-height));
  margin: 0 auto;
}

</style>
