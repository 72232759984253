import { defineNuxtPlugin } from '#app/nuxt'
import { LazySbAlbumPage, LazySbAutoGrid, LazySbButton, LazySbCategoryPage, LazySbContactForm, LazySbContactPage, LazySbContainer, LazySbEventPage, LazySbGenericPage, LazySbHero, LazySbHomePage, LazySbHomeVideo, LazySbIFrame, LazySbImage, LazySbMovie, LazySbNewsItem, LazySbNewsOverview, LazySbNewsPage, LazySbPerson, LazySbProductPage, LazySbSpacer, LazySbSplitPage, LazySbSponsorScroller, LazySbText } from '#components'
const lazyGlobalComponents = [
  ["SbAlbumPage", LazySbAlbumPage],
["SbAutoGrid", LazySbAutoGrid],
["SbButton", LazySbButton],
["SbCategoryPage", LazySbCategoryPage],
["SbContactForm", LazySbContactForm],
["SbContactPage", LazySbContactPage],
["SbContainer", LazySbContainer],
["SbEventPage", LazySbEventPage],
["SbGenericPage", LazySbGenericPage],
["SbHero", LazySbHero],
["SbHomePage", LazySbHomePage],
["SbHomeVideo", LazySbHomeVideo],
["SbIFrame", LazySbIFrame],
["SbImage", LazySbImage],
["SbMovie", LazySbMovie],
["SbNewsItem", LazySbNewsItem],
["SbNewsOverview", LazySbNewsOverview],
["SbNewsPage", LazySbNewsPage],
["SbPerson", LazySbPerson],
["SbProductPage", LazySbProductPage],
["SbSpacer", LazySbSpacer],
["SbSplitPage", LazySbSplitPage],
["SbSponsorScroller", LazySbSponsorScroller],
["SbText", LazySbText],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
