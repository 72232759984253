<template>
  <div
    :class="[
      $style['container'],
      {[$style['container--no-padding']]: noPadding},
      {[$style['container--padding-top']]: paddingTop},
      {[$style['container--full-width']]: fullWidth},
      {[$style['container--full-height']]: fullHeight},
    ]">
    <slot />
  </div>
</template>
<script setup>
defineProps({
  noPadding: {
    type: Boolean,
    default: false
  },
  paddingTop: {
    type: Boolean,
    default: false
  },
  fullWidth: {
    type: Boolean,
    default: false
  },
  fullHeight: {
    type: Boolean,
    default: false
  }
})
</script>
<style module>
.container {
  max-width: var(--container-width);
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-xxl) 0;
}

@media (width <= 50rem) {
  .container {
    padding: var(--spacing-xl);
  }
}

.container--no-padding {
  padding: 0;
}

.container--padding-top {
  padding-top: calc(var(--topbar-height) + var(--spacing-xxxxl));
}

.container--full-width {
  max-width: 100%;
}

.container--full-height {
  min-height: calc(100vh - var(--topbar-height));
}

</style>
